.dataTables_paginate {
    .pagination  {
        float: right;
    }
}

.dataTables_filter {
    label {
        float: right;
    }
}

.rangeslider__fill {
    background-color: #007BFF !important;
}